import React from 'react';
import Link from 'next/link';
import { IoMdPersonAdd, IoIosInformation } from 'react-icons/io';
import { BiSolidTrophy } from 'react-icons/bi';
import { FaRegCalendarTimes, FaCalendarAlt } from 'react-icons/fa';
import { TbTrophyOff } from 'react-icons/tb';
import { RiVipCrownLine } from 'react-icons/ri';
import { useSelector } from 'react-redux';

import { MdPerson } from 'react-icons/md';
import { FaMoneyBill1Wave } from 'react-icons/fa6';

const redirectToRaffle = (raffle) => {
  let redirectLink;
  if (raffle.sponsor.name) {
    redirectLink = `/cekilisler/${raffle?.sponsor?.name}-${raffle?.prize}tl-${raffle?.type}-etkinligi-${raffle?.order}`;
  } else {
    redirectLink = `/cekilisler/${raffle?.sponsor}-${raffle?.prize}tl-${raffle?.type}-etkinligi-${raffle?.order}`;
  }
  return redirectLink;
};

const RaffleButton = ({ raffle, isJoined, notJoined }) => {
  const user = useSelector((state) => state.user);

  const userStatusTranslations = {
    1: 'Silver VIP Özel Etkinlik',
    2: 'Gold VIP Özel Etkinlik',
    3: 'Platinum VIP Özel Etkinlik',
    4: 'Diamond VIP Özel Etkinlik',
  };

  return (
    <div className='w-3/4'>
      {isJoined && raffle.type === 'bilet' && raffle.status === 'active' && (
        <Link
          href={redirectToRaffle(raffle)}
          className='backgradientSoft hover:shadow-accentBlue/20 mt-2 flex items-center justify-center gap-2 rounded-lg px-4 py-2 text-white transition-all hover:shadow-lg'
        >
          <FaMoneyBill1Wave /> Yatırım Ekle
        </Link>
      )}
      {isJoined && raffle.type !== 'bilet' && raffle.status !== 'resulted' && (
        <div className='backgradientSoft mt-2 flex cursor-not-allowed items-center justify-center gap-2 rounded-lg px-4 py-2 text-white opacity-40'>
          <MdPerson /> Katıldın
        </div>
      )}

      {notJoined && raffle.status === 'active' && !raffle.vipLevel && (
        <Link
          href={redirectToRaffle(raffle)}
          className='backgradientSoft hover:shadow-accentBlue/20 mt-2 flex items-center justify-center gap-2 rounded-lg px-4 py-2 text-white transition-all hover:shadow-lg'
        >
          <IoMdPersonAdd /> Katıl
        </Link>
      )}
      {notJoined &&
        user.vipLevel >= raffle.vipLevel &&
        raffle.status === 'active' && (
          <Link
            href={redirectToRaffle(raffle)}
            className='backgradientSoft mt-2 flex items-center justify-center gap-2 rounded-lg px-4 py-2 text-white hover:shadow-lg'
          >
            <RiVipCrownLine className='h-5 w-5 text-yellow-400' /> Katıl
          </Link>
        )}
      {notJoined &&
        raffle.status === 'active' &&
        raffle.vipLevel > user.vipLevel && (
          <div className='backgradientSoft mt-2 flex items-center justify-center gap-2 rounded-lg px-4 py-2 text-white opacity-80'>
            <RiVipCrownLine /> {userStatusTranslations[raffle.vipLevel]}
          </div>
        )}

      {notJoined && raffle.status === 'inactive' && (
        <div className='backgradientSoft mt-2 flex items-center justify-center gap-2 rounded-lg px-4 py-2 text-white opacity-80'>
          <MdPerson /> Aktif Değil
        </div>
      )}

      {notJoined && raffle.status === 'soon' && (
        <div className='backgradientSoft mt-2 flex items-center justify-center gap-2 rounded-lg px-4 py-2 text-white opacity-80'>
          <FaCalendarAlt /> Yakında
        </div>
      )}

      {raffle.status === 'ended' && (
        <div className='backgradientSoft mt-2 flex items-center justify-center gap-2 rounded-lg px-4 py-2 text-white opacity-80'>
          <FaRegCalendarTimes /> Bitti
        </div>
      )}

      {raffle.status === 'resulted' && !raffle.hasWon && (
        <div className='backgradientSoft mt-2 flex items-center justify-center gap-2 rounded-lg px-4 py-2 text-white opacity-40 ring ring-red-500'>
          <TbTrophyOff /> Kazanamadın
        </div>
      )}
      {raffle.status === 'resulted' && raffle.hasWon && (
        <Link
          href={redirectToRaffle(raffle)}
          className='backgradientSoft mt-2 flex items-center justify-center gap-2 rounded-lg px-4 py-2 text-white ring ring-green-500'
        >
          <BiSolidTrophy /> KAZANDIN <BiSolidTrophy />
        </Link>
      )}
    </div>
  );
};

export default RaffleButton;
