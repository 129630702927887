import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import {
  IoMdPersonAdd,
  IoIosInformation,
  IoIosInformationCircleOutline,
} from 'react-icons/io';
import { MdPerson } from 'react-icons/md';
import { FaFire } from 'react-icons/fa';
import { IoSparkles } from 'react-icons/io5';
import { RiVipCrownLine } from 'react-icons/ri';
import { FaStar, FaUserLargeSlash } from 'react-icons/fa6';
import { FaUserCheck, FaCalendarAlt } from 'react-icons/fa';
import RaffleButton from './RaffleBoxComponents/RaffleButton';

const RaffleBox = ({ raffle, loading }) => {
  const getImg = (raffle) => {
    const baseUrl = 'https://d38ezbe56qv4qs.cloudfront.net/img/sponsors/';
    if (raffle?.sponsor.name) {
      return baseUrl + raffle?.sponsor.name + '.webp';
    } else {
      return baseUrl + raffle?.sponsor + '.webp';
    }
  };

  const redirectToRaffle = (raffle) => {
    let redirectLink;
    if (raffle.sponsor.name) {
      redirectLink = `/cekilisler/${raffle?.sponsor?.name}-${raffle?.prize}tl-${raffle?.type}-etkinligi-${raffle?.order}`;
    } else {
      redirectLink = `/cekilisler/${raffle?.sponsor}-${raffle?.prize}tl-${raffle?.type}-etkinligi-${raffle?.order}`;
    }
    return redirectLink;
  };

  const preventLinkOnDrag = (e) => {
    e.preventDefault();
  };

  const isNewRaffle = (createdAt) => {
    const oneHour = 60 * 60 * 1000; // milliseconds in one hour
    const raffleDate = new Date(createdAt);
    const now = new Date();

    return now - raffleDate < oneHour;
  };

  const isPopularRaffle = (raffle) => {
    return raffle?.entrants?.length > 2;
  };

  const isGreatAmount = (raffle) => {
    return raffle?.prize > 100000;
  };

  const isJoined = (raffle) => {
    return raffle?.joined;
  };

  const notJoined = (raffle) => {
    return !raffle?.joined;
  };

  const isFeatured = (raffle) => {
    return raffle?.isFeatured;
  };

  const isSoon = (raffle) => {
    return raffle?.status === 'soon';
  };

  const hasWon = (raffle) => {
    return raffle?.hasWon;
  };

  const isForVip = (raffle) => {
    return raffle?.isForVIP;
  };

  return (
    <div
      key={raffle?.raffleID}
      className={`bg-bgDark group relative flex min-h-[230px] min-w-[80%] flex-col items-center overflow-hidden rounded-lg p-4 transition sm:min-w-[60%] lg:min-w-[270px]`}
    >
      <div className='absolute left-2 top-1 flex items-center gap-0'>
        {isForVip(raffle) && (
          <div className='group flex cursor-default items-center gap-1 rounded-md px-1 py-1 text-xs font-semibold uppercase text-yellow-400'>
            <RiVipCrownLine className='text-lg' />
            <span className='w-0 overflow-hidden transition-all duration-300 group-hover:w-fit'>
              VIP Özel
            </span>
          </div>
        )}
        {isNewRaffle(raffle?.createdAt) && (
          <div className='flex cursor-default items-center gap-1 rounded-md px-1 py-1 text-xs font-semibold uppercase text-btnGreen'>
            <IoSparkles className='text-lg' />
            <span className='w-0 overflow-hidden transition-all duration-300 group-hover:w-fit'>
              Yeni
            </span>
          </div>
        )}
        {isGreatAmount(raffle) && (
          <div className='group flex cursor-default items-center gap-1 rounded-md px-1 py-1 text-xs font-semibold uppercase text-btnGreen'>
            <IoSparkles className='text-lg' />
            <span className='w-0 overflow-hidden transition-all duration-300 group-hover:w-fit'>
              Büyük
            </span>
          </div>
        )}
        {isJoined(raffle) && (
          <div className='group flex cursor-default items-center gap-1 rounded-md px-1 py-1 text-xs font-semibold uppercase text-green-500'>
            <FaUserCheck className='text-lg' />
            <span className='w-0 overflow-hidden transition-all duration-300 group-hover:w-fit'>
              Katıldın
            </span>
          </div>
        )}
        {notJoined(raffle) && raffle.status !== 'soon' && (
          <div className='text-accentPink group flex cursor-default items-center gap-1 rounded-md px-1 py-1 text-xs font-semibold uppercase'>
            <FaUserLargeSlash className='text-lg' />
            <span className='w-0 overflow-hidden transition-all duration-300 group-hover:w-fit'>
              Katılmadın
            </span>
          </div>
        )}
        {isSoon(raffle) && (
          <div className='group flex cursor-default items-center gap-1 rounded-md px-1 py-1 text-xs font-semibold uppercase text-btnBlue'>
            <FaCalendarAlt className='text-lg' />
            <span className='w-0 overflow-hidden transition-all duration-300 group-hover:w-fit'>
              Yakında
            </span>
          </div>
        )}
        {isPopularRaffle(raffle) && (
          <div className='group flex cursor-default items-center gap-1 rounded-md px-1 py-1 text-xs font-semibold uppercase text-btnRed'>
            <FaFire className='text-lg' />
            <span className='w-0 overflow-hidden transition-all duration-300 group-hover:w-fit'>
              Popüler
            </span>
          </div>
        )}
        {isFeatured(raffle) && (
          <div className='group flex cursor-default items-center gap-1 rounded-md px-1 py-1 text-xs font-semibold uppercase text-btnYellow'>
            <FaStar className='text-lg' />
            <span className='w-0 overflow-hidden transition-all duration-300 group-hover:w-fit'>
              Önerilen
            </span>
          </div>
        )}
        {hasWon(raffle) && (
          <div className='group flex cursor-default items-center gap-1 rounded-md px-1 py-1 text-xs font-semibold uppercase text-btnYellow'>
            <FaStar className='text-lg' />
            <span className='w-0 overflow-hidden transition-all duration-300 group-hover:w-fit'>
              Kazandın
            </span>
          </div>
        )}
      </div>
      <div className='absolute right-1 top-1 flex items-center rounded-md px-2 py-1 text-sm text-text'>
        <Link href={redirectToRaffle(raffle)}>
          <IoIosInformationCircleOutline className='text-xl text-text opacity-60 transition-all duration-300 hover:text-main hover:opacity-100' />
        </Link>
      </div>
      <Link
        href={`https://xkisa.de/${
          raffle?.sponsor?.name ? raffle.sponsor.name : raffle.sponsor
        }`}
        target='_blank'
        rel='noopener'
        draggable='false'
      >
        <Image
          draggable='false'
          width={160}
          height={80}
          src={getImg(raffle)}
          alt={`${
            raffle?.sponsor?.name ? raffle.sponsor.name : raffle.sponsor
          } Image`}
          className='rounded-md'
        />
      </Link>
      <div className='flex flex-col items-center'>
        {/* event name */}
        <span className='textGradientBlue block text-lg font-bold capitalize'>
          {`${raffle?.type} etkinliği`}
        </span>
        {/* prize */}
        <span className='-text-2xl font-medium uppercase text-text'>
          {raffle?.prize?.toLocaleString('tr-TR')} TL
        </span>
      </div>
      <RaffleButton
        raffle={raffle}
        isJoined={isJoined(raffle)}
        notJoined={notJoined(raffle)}
      />
    </div>
  );
};

export default RaffleBox;
